body {
  margin: 0;
  padding: 0;
  font-family: 'Neue Haas Grotesk';
  font-weight: 400;
}

::-webkit-scrollbar {
  display: none;
}

/* -----------------------------------------------------------------
 * Below are the CSS definitions for fonts imported in this project.
 * ----------------------------------------------------------------- */

@font-face {
  font-family: 'Neue Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  src: local('Neue Haas Grotesk'), url('./__assets__/fonts/NHaasGroteskTXPro-55Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  src: local('Neue Haas Grotesk'), url('./__assets__/fonts/NHaasGroteskTXPro-65Md.ttf') format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk';
  font-style: normal;
  font-weight: 600;
  src: local('Neue Haas Grotesk'), url('./__assets__/fonts/NHaasGroteskTXPro-75Bd.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Serif KR';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Serif KR Light'), local('NotoSerifKR-Light'),
       url('./__assets__/fonts/NotoSerifKR-Light.otf') format('woff');
}

@font-face {
  font-family: 'Noto Serif KR';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Serif KR'), local('NotoSerifKR-Regular'),
       url('./__assets__/fonts/NotoSerifKR-Regular.otf') format('woff');
}

@font-face {
  font-family: 'Noto Serif KR';
  font-style: normal;
  font-weight: 600;
  src: local('Noto Serif KR SemiBold'), local('NotoSerifKR-SemiBold'),
       url('./__assets__/fonts/NotoSerifKR-SemiBold.otf') format('woff');
}

@font-face {
  font-family: 'Noto Serif KR';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Serif KR Medium'), local('NotoSerifKR-Medium'),
       url('./__assets__/fonts/NotoSerifKR-Medium.otf') format('woff');
}

@font-face {
  font-family: 'Noto Serif KR';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Serif KR Bold'), local('NotoSerifKR-Bold'),
       url('./__assets__/fonts/NotoSerifKR-Bold.otf') format('woff');
}

@font-face {
  font-family: 'Noto Serif KR';
  font-style: normal;
  font-weight: 900;
  src: local('Noto Serif KR Black'), local('NotoSerifKR-Black'),
       url('./__assets__/fonts/NotoSerifKR-Black.otf') format('woff');
}

@font-face {
  font-family: 'Nimbus Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Nimbus Sans'), url('./__assets__/fonts/nimbus-sans-l_regular.ttf') format('truetype');
}
